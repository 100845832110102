<template>
  <div
    v-show="showModal"
    class="layer-survey-detail"
  >
    <div
      class="medical-detail-wrap"
      style="width: inherit"
    >
      <div class="layer-utility">
        <button
          class="btn-close"
          @click.prevent="beforeClose"
        >
          close
        </button>
      </div>

      <div class="md-header survey-layer-type">
        <dl>
          <dt style="font-size: 24px;">
            {{ title }}
          </dt>
          <dd>{{ startDate | $dateFormatter }}{{ startDate ? ' ~ ' : '' }}{{ endDate | $dateFormatter }}</dd>
        </dl>
      </div>

      <div class="md-contents">
        <div class="survey-layer-area">
          <div
            v-dompurify-html="currPage ? currPage.description : ''"
            class="survey-welcome-info"
          />

          <div class="survey-list">
            <template
              v-for="q in elements"
            >
              <div
                v-if="q.isShow"
                :key="`el${q.id}`"
                :ref="`el${q.id}`"
                class="survey-item"
              >
                <dl>
                  <dt>
                    <label
                      v-show="q.isRequired"
                      class="essential-label"
                    >필수</label>
                    <p><span>{{ q.dispSeq }}.</span>{{ q.title }}</p>
                  </dt>
                  <dd v-if="q.description">
                    <div
                      v-dompurify-html="q.description"
                      class="gray-box"
                    />
                  </dd>
                  <dd>
                    <!-- 선택(단일) -->
                    <template v-if="q.type === 1">
                      <ul>
                        <template
                          v-for="ch in q.choices"
                        >
                          <li :key="`ch${ch.id}`">
                            <div class="sur-radio-wrap">
                              <input
                                :id="`ch${ch.id}`"
                                v-model="q.answer"
                                :value="ch.id"
                                :name="`el${q.id}`"
                                type="radio"
                                @change.prevent="changeAnswer()"
                              >
                              <label :for="`ch${ch.id}`">{{ ch.description }}</label>
                            </div>
                          </li>
                          <li
                            v-if="ch.isEtc"
                            :key="`ch-other${ch.id}`"
                            class="mgl28"
                          >
                            <div class="text-wrap">
                              <input
                                v-model="q.answerEtcText"
                                type="text"
                                :disabled="q.answer !== ch.id"
                              >
                            </div>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <!-- 선택(복수) -->
                    <template v-if="q.type === 2">
                      <ul>
                        <template
                          v-for="ch in q.choices"
                        >
                          <li :key="`ch${ch.id}`">
                            <div class="sur-checkbox-wrap">
                              <input
                                :id="`ch${ch.id}`"
                                v-model="q.answer"
                                :value="ch.id"
                                type="checkbox"
                                @change.prevent="changeAnswer()"
                              >
                              <label :for="`ch${ch.id}`">{{ ch.description }}</label>
                            </div>
                          </li>
                          <li
                            v-if="ch.isEtc"
                            :key="`ch-other${ch.id}`"
                            class="mgl28"
                          >
                            <div class="text-wrap">
                              <input
                                v-model="q.answerEtcText"
                                type="text"
                                :disabled="q.answer.indexOf(ch.id) === -1"
                              >
                            </div>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <!-- 텍스트(한줄) -->
                    <template v-if="q.type === 3">
                      <div class="text-wrap">
                        <input
                          v-model="q.answer"
                          @input="filteredValue(q)"
                          @change.prevent="changeAnswer()"
                        >
                      </div>
                    </template>

                    <!-- 텍스트(여러줄) -->
                    <template v-if="q.type === 4">
                      <div class="textarea-wrap">
                        <textarea
                          v-model="q.answer"
                          :rows="q.rows"
                          @input="filteredValue(q)"
                          @change.prevent="changeAnswer()"
                        />
                      </div>
                    </template>

                    <!-- 순위형 -->
                    <template v-if="q.type === 5">
                      <ul>
                        <template
                          v-for="ch in q.choices"
                        >
                          <li :key="`ch${ch.id}`">
                            <button
                              class="btnRanking"
                              :class="{ active: ch.rank }"
                              @click.prevent="clickRank(q, ch.id)"
                            >
                              <span class="ranking"><em>{{ ch.rank }}</em></span>{{ ch.description }}
                            </button>
                          </li>
                          <li
                            v-if="ch.isEtc"
                            :key="`ch-other${ch.id}`"
                            class="mgl28"
                          >
                            <div class="text-wrap">
                              <input
                                v-model="q.answerEtcText"
                                type="text"
                                :disabled="q.answer.indexOf(ch.id) === -1"
                              >
                            </div>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <!-- 자동제출 -->
                    <template v-if="q.type === 10">
                      &nbsp;
                    </template>
                  </dd>
                </dl>
              </div>
              <div
                v-show="getErrorMessage(q.id)"
                :key="`invalid${q.id}`"
                class="message-box"
              >
                <p class="essential-message">
                  {{ getErrorMessage(q.id) }}
                </p>
              </div>
            </template>
          </div>

          <div
            v-if="elements && elements.length"
            class="btn-wrap"
          >
            <button
              :class="currPageIdx > 0 ? 'btn-sendIn' : 'btn-before'"
              @click.prevent="prevPage"
            >
              이전
            </button>
            <button
              v-if="pages.length && currPageIdx < pages.length - 1"
              class="btn-sendIn"
              @click.prevent="nextPage"
            >
              다음
            </button>
            <button
              v-if="pages.length && currPageIdx === pages.length - 1"
              class="btn-sendIn"
              @click.prevent="nextPage"
            >
              제출하기
            </button>
          </div>
        </div>
      </div>
    </div>
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { errorFormatter } from '@/libs/utils/filters'

export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },

    entry: {
      type: Number,
      required: false,
      default: null,
    },

    showModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      title: null,
      startDate: null,
      endDate: null,
      pages: [],
      elements: [],
      currPage: null,
      currPageIdx: null,
      errorMessages: {},

      // 문항 1개라도 입력값이 변경된 경우 true
      modified: false,
    }
  },

  computed: {
    ...mapGetters({
      medicalDept: 'infoData/getMedicalDept',
      shaYoyangNum: 'infoData/getShaYoyangNum',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    }),
  },

  watch: {
    id(to) {
      if (to) {
        this.fetchSurveyPaper(to)
      }
    },
  },

  methods: {
    prevPage() {
      if (!this.currPageIdx) {
        return
      }
      this.loadPage((this.currPageIdx - 1))
    },

    async nextPage() {
      // 페이지 이동(또는 제출 전) Validation
      const inValidElements = this.validate()
      if (inValidElements.length) {
        const firstErrorRef = `el${inValidElements[0]}`
        this.$refs[firstErrorRef][0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
        return
      }
      // Validation end.

      if (this.currPageIdx !== null && (this.currPageIdx + 1) === this.pages.length) {
        // 마지막 페이지인 경우
        this.showAlertDialog('미리보기 모드에서는 제출이 불가합니다.')
      } else {
        let loadPageIdx = null
        if (this.currPage === null) {
          loadPageIdx = 0
        } else {
          loadPageIdx = this.currPageIdx + 1
        }

        this.loadPage(loadPageIdx)
      }
    },

    loadPage(pageIdx) {
      // 페이지 로드
      this.currPageIdx = pageIdx
      this.currPage = this.pages[pageIdx]
      this.fetchPage(this.currPage.id)
    },

    getErrorMessage(elementId) {
      return Object.keys(this.errorMessages).length === 0 ? null : (this.errorMessages[elementId] || null)
    },

    // Validation
    validate() {
      const inValidElements = []
      const { elements } = this

      for (let i = 0; i < elements.length; i += 1) {
        let isValid = true
        const el = elements[i]
        if (el.isShow === false) {
          // 숨겨진 문항은 Validation 체크 제외
          this.$delete(this.errorMessages, el.id)
          continue
        }
        // 필수 입력 체크
        if (el.isRequired) {
          if (el.type === 10) {
            // 자동제출 유형
            if (!el.autoAnswers || el.autoAnswers.length === 0) {
              isValid = false
            } else {
              let existResults = 0
              for (let j = 0; j < el.autoAnswers.length; j += 1) {
                if (el.autoAnswers[j].autoResult) {
                  existResults += 1
                }
              }
              isValid = (existResults > 0)
            }
          } else if (!el.answer || el.answer.length === 0) {
            // 그 외 유형
            isValid = false
          }

          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)
            this.$set(this.errorMessages, el.id, '답변 필수 문항입니다.')
          }
        }
        // 기타텍스트 입력 여부(선택[단일,복수], 순위형에 해당)
        if (isValid && (el.type === 1 || el.type === 2 || el.type === 5)) {
          let etcId = 0
          for (let j = 0; j < el.choices.length; j += 1) {
            if (el.choices[j].isEtc) {
              etcId = el.choices[j].id
            }
          }
          if (etcId !== 0) {
            if (el.type === 1 && el.answer === etcId && !el.answerEtcText) {
              isValid = false
            } else if ((el.type === 2 || el.type === 5) && el.answer.indexOf(etcId) !== -1 && !el.answerEtcText) {
              isValid = false
            }

            if (isValid) {
              this.$delete(this.errorMessages, el.id)
            } else {
              inValidElements.push(el.id)
              this.$set(this.errorMessages, el.id, '기타 내용을 입력하여 주시기 바랍니다.')
            }
          }
        }
        // 텍스트 답변 형식 체크
        // 전체(null or 0), 문자만(1), 숫자만(2)
        if (isValid && (el.type === 3 || el.type === 4)) {
          if (el.textType) {
            if (el.textType === 1) {
              if (!new RegExp('^([^0-9]*)$').test(el.answer)) {
                isValid = false
              }
            } else if (el.textType === 2) {
              if (!new RegExp('^[0-9]*$').test(el.answer)) {
                isValid = false
              }
            }

            if (isValid) {
              this.$delete(this.errorMessages, el.id)
            } else {
              inValidElements.push(el.id)
              this.$set(this.errorMessages, el.id, `${el.textType === 1 ? '문자' : '숫자'}만 입력 가능합니다.`)
            }
          }
        }
        // 최소 체크 개수 충족여부
        if (isValid && el.minCheck !== null) {
          if (el.minCheck > el.answer.length) {
            isValid = false
          }
          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)

            let inValidMessage = ''
            if (el.type === 3 || el.type === 4) {
              // 텍스트 유형
              inValidMessage = `최소 ${el.minCheck}글자 이상 입력하여 주시기 바랍니다.`
            } else {
              // 체크, 순위 유형
              inValidMessage = `최소 ${el.minCheck}개 이상 체크하여 주시기 바랍니다.`
            }
            this.$set(this.errorMessages, el.id, inValidMessage)
          }
        }
        // 최대 체크 개수 충족여부
        if (isValid && el.maxCheck !== null) {
          if (el.maxCheck < el.answer.length) {
            isValid = false
          }
          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)

            let inValidMessage = ''
            if (el.type === 3 || el.type === 4) {
              // 텍스트 유형
              inValidMessage = `최대 ${el.maxCheck}글자 미만 입력하여 주시기 바랍니다.`
            } else {
              // 체크, 순위 유형
              inValidMessage = `최대 ${el.maxCheck}개 이하로 체크하여 주시기 바랍니다.`
            }
            this.$set(this.errorMessages, el.id, inValidMessage)
          }
        }
      } // for i end.
      return inValidElements
    },

    // 현재 페이지에 표시될 문항의 연결 여부를 확인하여 재 표시
    displayElements() {
      const { elements } = this
      const keepShowElements = []
      for (let i = 0; i < elements.length; i += 1) {
        const el = elements[i]
        if (el.type === 1 || el.type === 2 || el.type === 5) {
          // 선택형 문항(라디오, 체크박스, 순위형)에 대한 처리
          const { choices } = el
          for (let j = 0; j < choices.length; j += 1) {
            const ch = choices[j]
            let showElements = null
            if (ch.showElementList && ch.showElementList.length) {
              showElements = ch.showElementList
            } else {
              // showElements 없으면, 건너 뜀
              // eslint-disable-next-line no-continue
              continue
            }

            let isCheck = false
            if (el.answer && el.answer === ch.id) {
              isCheck = true
            } else if (el.answer && typeof el.answer === 'object' && el.answer.indexOf(ch.id) !== -1) {
              isCheck = true
            }

            for (let k = 0; k < elements.length; k += 1) {
              if (showElements.indexOf(elements[k].seq) !== -1) {
                if (isCheck && keepShowElements.indexOf(elements[k].seq) === -1) {
                  keepShowElements.push(elements[k].seq)
                }

                if (!isCheck && keepShowElements.indexOf(elements[k].seq) !== -1) {
                  // elements[k].isShow = true
                  this.$set(elements[k], 'isShow', true)
                } else {
                  // elements[k].isShow = isCheck
                  this.$set(elements[k], 'isShow', isCheck)
                }
              }
            }
          } // for j end.
        } else if (el.type === 3 || el.type === 4) {
          // 텍스트, 코멘트 유형에 대한 처리
          let inputShowElements = null
          if (el.inputShowElementList) {
            inputShowElements = el.inputShowElementList
          } else {
            // eslint-disable-next-line no-continue
            continue
          }
          // 텍스트 유형은 한 글자라도 입력하면 true
          const isCheck = !!el.answer
          for (let j = 0; j < elements.length; j += 1) {
            if (inputShowElements.indexOf(elements[j].seq) !== -1) {
              // InputShowElements에 속한 문항은 숨김 처리
              // elements[j].isShow = isCheck
              this.$set(elements[j], 'isShow', isCheck)
            }
          }
        }
      } // for i end.

      // 문항번호 재 지정
      this.resetElementSequence()
    },

    // 문항 표시 변경 이후 번호 재 지정
    resetElementSequence() {
      let sequence = 1
      const { elements } = this
      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i].isShow !== false) {
          elements[i].dispSeq = sequence
          sequence += 1
        }
      }
    },

    clickRank(el, choiceId) {
      const { answer } = el

      if (choiceId) {
        const existIdx = answer.indexOf(choiceId)
        if (existIdx !== -1) {
          // 선택목록에 들어 있으면
          answer.splice(existIdx, 1)
        } else {
          answer.push(choiceId)
        }
      }

      this.changeAnswer()
      this.refreshRank(el)
    },

    refreshRank(el) {
      const { answer } = el
      const { choices } = el

      // 선택목록 순서대로 Rank 재 할당
      for (let i = 0; i < choices.length; i += 1) {
        this.$set(choices[i], 'rank', null)
      }
      for (let i = 0; i < answer.length; i += 1) {
        for (let j = 0; j < choices.length; j += 1) {
          if (answer[i] === choices[j].id) {
            this.$set(choices[j], 'rank', (i + 1))
          }
        }
      }
    },

    changeAnswer() {
      this.modified = true

      this.displayElements()
    },

    filteredValue(element) {
      const el = element
      let inputValue = el.answer

      if (el.maxCheck && inputValue.length > el.maxCheck) {
        inputValue = inputValue.slice(0, el.maxCheck)
      }
      if (el.textType === 1) {
        inputValue = inputValue.replace(/[0-9]/, '')
      }
      if (el.textType === 2) {
        inputValue = inputValue.replace(/[^0-9]/g, '')
      }

      el.answer = inputValue
    },

    fetchSurveyPaper(id) {
      axios.get(`/fu/survey-preview/${id}`)
        .then(rs => {
          this.title = rs.data.title
          this.pages = rs.data.pages

          if (this.pages.length === 0) {
            this.showAlertDialog('설문 정보가 잘못 입력되었습니다.\n관리자에게 문의해 주세요.')
            return
          }

          this.loadPage(0)
        })
        .catch(err => {
          this.showAlertDialog(errorFormatter(err, '설문조사를 불러오는데 실패하였습니다.\n잠시 후 다시 시도해 주세요.'))
        })
    },

    fetchPage(pageId) {
      axios.get(`/fu/survey-preview/elements/${pageId}`)
        .then(rs => {
          const { elements } = rs.data

          for (let i = 0; i < elements.length; i += 1) {
            const el = elements[i]

            if (!el.answer) {
              if (el.type === 2 || el.type === 5) {
              // 선택[복수]형(2), 순위형(5) 설문은 배열 값으로 초기화
                el.answer = []
              } else {
                el.answer = null
              }
            }
            el.isShow = true // 보여주기 기본 값 true
            el.dispSeq = (i + 1) // 문항표시번호 기본 값
          }

          this.elements = elements
          this.displayElements()
        })
        .catch(() => {
          this.showAlertDialog('페이지 문항을 불러오는데 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },

    beforeClose() {
      this.close(false)
    },

    close() {
      this.title = null
      this.startDate = null
      this.endDate = null
      this.pages = []
      this.elements = []
      this.currPage = null
      this.currPageIdx = null
      this.errorMessages = {}
      this.modified = false
    },
  },
}
</script>
